/* global fbq */
import "./src/css/index.css"
import "aos/dist/aos.css"
import "./src/css/fontello.css"
window.kAnim = (target, number, duration = 2000) => {
  const POINT_REGEX = new RegExp(/\./)
  const IS_DEC = POINT_REGEX.test(number)

  let startTimestamp = null;
  const step = (timestamp) => {
    if (!startTimestamp) startTimestamp = timestamp;
    const progress = Math.min((timestamp - startTimestamp) / duration, 1);

    let ModNumber = number
    if (!IS_DEC) {
      ModNumber = number.match(/\d+/g).join(" ")
    }
    const processOutput = Math.floor(progress * (ModNumber - 0) + 0);

    if (IS_DEC) {
      target.innerText = parseFloat(processOutput).toFixed(2)
    } else {
      target.innerText = number.replace(/\d+/g, parseInt(processOutput).toFixed(0))
    }

    if (progress < 1) {
      window.requestAnimationFrame(step);
    }
  };
  window.requestAnimationFrame(step);
};

function getParam(p) {
  var match = RegExp("[?&]" + p + "=([^&]*)").exec(window.location.search)
  return match && decodeURIComponent(match[1].replace(/\+/g, " "))
}

function getExpiryRecord(value) {
  var expiryPeriod = 90 * 24 * 60 * 60 * 1000

  var expiryDate = new Date().getTime() + expiryPeriod
  return {
    value: value,
    expiryDate: expiryDate,
  }
}

function addGclid() {
  var gclidParam = getParam("gclid")
  var gclidFormFields = ["gclid_field", "foobar"]
  var gclidRecord = null
  var currGclidFormField

  var gclsrcParam = getParam("gclsrc")
  var isGclsrcValid = !gclsrcParam || gclsrcParam.indexOf("aw") !== -1

  gclidFormFields.forEach(function (field) {
    if (document.getElementById(field)) {
      currGclidFormField = document.getElementById(field)
    }
  })

  if (gclidParam && isGclsrcValid) {
    gclidRecord = getExpiryRecord(gclidParam)
    localStorage.setItem("gclid", JSON.stringify(gclidRecord))
  }
  // eslint-disable-next-line no-redeclare
  var gclsrcParam = getParam("gclsrc")
  // eslint-disable-next-line no-redeclare
  var isGclsrcValid = !gclsrcParam || gclsrcParam.indexOf("aw") !== -1

  gclidFormFields.forEach(function (field) {
    if (document.getElementById(field)) {
      currGclidFormField = document.getElementById(field)
    }
  })

  if (gclidParam && isGclsrcValid) {
    gclidRecord = getExpiryRecord(gclidParam)
    localStorage.setItem("gclid", JSON.stringify(gclidRecord))
  }

  var gclid = gclidRecord || JSON.parse(localStorage.getItem("gclid"))
  var isGclidValid = gclid && new Date().getTime() < gclid.expiryDate

  if (currGclidFormField && isGclidValid) {
    currGclidFormField.value = gclid.value
  }
}

window.addEventListener("load", addGclid)

export const onClientEntry = () => {
  const timerId = setTimeout(() => {
    // window.$crisp = [];
    // window.CRISP_WEBSITE_ID = "be383b11-eaf2-40df-b804-1dbfe8c7f4a2";
    const script = document.createElement("script");
    script.src = "//code.tidio.co/mhpejcolzqzaeiybsqshfdofymswh5li.js";
    script.defer = true;
    // document.head.appendChild(script);
    document.body.insertAdjacentElement("afterend",script);
    (function(w,d,s,l,i){
      w[l]=w[l]||[];
      w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
      var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!=='dataLayer'?'&l='+l:'';
      j.defer=true;
      j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
      f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-NNW89M8');
    
    // Facebook Pixel Script
    (function(f,b,e,v,n,t,s) {
      if(f.fbq)return;
      n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;
      n.push=n;
      n.loaded=!0;
      n.version='2.0';
      n.queue=[];
      t=b.createElement(e);
      t.async=!0;
      t.src=v;
      s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s);
    })(window,document,'script','https://connect.facebook.net/en_US/fbevents.js');

    // Initialize Facebook Pixel
    if (typeof fbq !== 'undefined') {
      fbq('init', '1574636285961650');
      fbq('track', 'PageView');
    }

    // Add noscript pixel
    const noscript = document.createElement('noscript');
    const img = document.createElement('img');
    img.height = '1';
    img.width = '1';
    img.style.display = 'none';
    img.src = 'https://www.facebook.com/tr?id=1574636285961650&ev=PageView&noscript=1';
    noscript.appendChild(img);
    document.body.appendChild(noscript); 
  }, 9000);
  return () => clearTimeout(timerId);
};

